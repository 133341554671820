@import "../../../shared/assets/styles/colors";
@import "../../../shared/assets/styles/constants";

.label_description {
  margin: 30px 0;
  display: flex;
  @media screen and #{$media_mobile_max_width_3} {
    margin: 20px 0;
  }

  &.recommended_value_label{
    align-items: center;
    gap: 5px;

    span {
      &:first-child {
        color: $gray-element;
      }
      &:last-child {
        white-space: nowrap;
      }
    }
  }
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media screen and #{$media_mobile_max_width_3} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  &__attention {
    margin: 40px 0;
    justify-content: space-evenly;
    @media screen and #{$media_mobile_max_width_3}{
      margin-top: 20px;
      margin-bottom: 30px;
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }
  }
  &__toggle {
    //display: flex; // раскомментить, когда нужен будет toggle
    display: none; // а тут убрать
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    @media screen and #{$media_mobile_max_width_3} {
      margin-top: 0;
      gap: 20px;
      margin-bottom: 20px;
    }
  }
}
.selector_label {
  width: 10.5rem;
  margin-bottom: 0;
  display: flex;

  @media screen and #{$media_mobile_max_width_3} {
    width: 100%;
    margin-bottom: 10px;
  }
}
.input {
  //width: 100%;
  font-family: Gotham, sans-serif;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  border: 1px solid $gray-field;
  border-radius: 10px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  &__icon {
    position: absolute;
    right: 1rem;
    @media screen and #{$media_mobile_max_width_3} {
      right: 1rem;
      bottom: 0.7rem;
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    width: 22.5rem;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    @media screen and #{$media_mobile_max_width_3} {
      width: 100%;
      flex-direction: column;
      //align-items: flex-start
      align-items: center;
    }
    input {
      height: 40px;
    }
  }
  &:hover {
    border: 1px solid $gray-normal;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    border-color: $green-help;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  &__noHint {
    &:focus {
      border-color: $black;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
  }
}
.is_invalid {
  border-color: $red-error !important;
}
