@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.drop_down_menu {
  position: absolute;
  background: linear-gradient(20deg, $white 0%, #f8f9fa 100%);
  width: 70px;
  //padding: .6rem .6rem;
  margin-top: 8rem;
  border: 1px solid $gray-field;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  z-index: 999;
  //bottom: -3.5rem;
  top: -4.7rem;
  @media screen and #{$media_mobile_max_width_3} {
    margin-top: 1.5rem;
    top: 1.2rem;
  }
  &__item {
    cursor: pointer;
    color: $white;

    &:hover {
      color: $black;
    }
  }

  &__inline-block {
    //left: 0;
    &--left {
      left: 0;
    }
    &--right {
      left: 0;
      @media screen and #{$media_mobile_max_width_3} {
        left: 11px;
      }
    }
  }
}
