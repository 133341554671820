@import "../../../shared/assets/styles/colors";

.title {
  margin: 2rem 0 0.3rem;
}
//.body {
//  display: flex;
//  justify-content: center;
//  flex-direction: column;
//}
.table {
  vertical-align: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .table_row{
    border-bottom: 1px solid $gray-normal;
    &:first-of-type {
      border-top: 2px solid $gray-normal;
    }
    &:last-child {
      border-bottom: 2px solid $gray-normal;
    }
    td {
      max-width: 10vw;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  tr {
    vertical-align: middle;
    line-height: 14px;
    a {
      padding: 0;
      width: 8vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  tr th:nth-child(odd) {
    padding: 1rem 0;
  }
  tr th:last-child {
    padding: 1rem 0 1rem 0.5rem;
  }
  tr td:nth-child(even) {
    background-color: $gray-inactive;
  }
  tr td:last-child {
    background-color: $white;
    padding-right: 0;
  }
}
.head_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &__left {
    padding-top: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
  }
  &__right {
    position: relative;
    display: flex;
    input {
      font-family: Gotham, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 15px;
      position: relative;
      border: 1px solid $gray-field;
      border-radius: 10px;
      width: 20vw;
      height: 45px;
      background-color: $gray-field;
      &.active {
        background-color: $white;
        color: $blue-normal;
      }
      &:hover {
        border: 1px solid $gray-normal;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      }
      &:focus {
        border-color: $gray-field;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        background-color: $white;
      }
    }
    .magnifier {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      border: 1px solid #DDDDDD;
      border-radius: 10px;
      background-color: $white;
    }
  }
  span {
    margin: 0;
  }
  .search_feedback {
    margin: 0;
    left: -20rem;
    bottom: -1rem;
    &:before {
      rotate: 180deg;
      right: -3.5%;
    }
  }
}
.spinner {
  color: $blue-normal;
  position: absolute;
  right: 50%;
  top: 50%;
}
.chart_preview_image{
  width: 10vw
  //svg {
  //  width: 100%;
  //  max-height: 40px;
  //}
}
.pagination_bar {
  width: 100%;
  padding: 0;
  justify-content: center;
}
.footer {
  position: relative;
}
