@import "../shared/assets/styles/colors";
@import "../shared/assets/styles/constants";

@font-face {
	font-family: 'Gotham-Book';
	src: local('Gotham-Book'),
	url(../shared/assets/fonts/Gotham-Book.woff) format('woff');

}
@font-face {
	font-family: 'Gotham-Medium';
	src: local('Gotham-Medium'),
	url(../shared/assets/fonts/Gotham-Medium.woff) format('woff');

}
@font-face {
	font-family: 'Gotham-Bold';
	src: local('Gotham-Bold'),
	url(../shared/assets/fonts/Gotham-Bold.woff) format('woff');

}
.app_container {
	padding: 0;
	@media screen and #{$media_mobile_max_width_3}{
		background-color: $gray-inactive;
	}
}
.App{

}
