@import "../../shared/assets/styles/_colors";
@import "../../shared/assets/styles/constants";


.language_panel {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.6rem;
  background-color: $white;

  @media screen and #{$media_mobile_max_width_3} {
    border: none;
    width: 3rem;
    padding: 0 0 0 20px;

  }
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    padding: 10px 8px;

    &:active,
    &:hover{
      background-color: $gray-inactive;
    }

    .symbol {
      width: 3rem;
    }

    .language_panel__item:hover &__text {
      color: $black;
    }

    &__text {
      inline-size: auto;
      margin-left: 9px;
      color: $gray-element;
      &__selected{
        font-weight: 600;
        font-size: 16px;
        margin-left: .5rem;
        &__auth{
          color: black;
        }
        &__client{
          color: #ADADAD;
          @media screen and #{$media_mobile_max_width_3} {
            color: white;
          }
        }
        @media screen and #{$media_mobile_max_width_3} {
          font-size: 14px;
          margin-left: .5rem;
        }
      }

      &:hover {
        color: $blue-normal;
      }
    }
  }
  .flag {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
  .flag_wrap {
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $gray-inactive;

    @media screen and #{$media_mobile_max_width_3} {
      border: 1px solid $gray-inactive;


    }

    &--active {
      @media screen and #{$media_mobile_max_width_3} {
        border: 1px solid $blue-normal;
      }
    }

  }

  br {
    display: none;
  }
}

