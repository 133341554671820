@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants";

.selector_main {
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 22.5rem;
  position: relative;

  @media screen and #{$media_mobile_max_width_3} {
    max-width: 100%;
  }

}
//.selector {
//  &__investor_account {
//    top: 51%;
//    left: 40%;
//  }
//  &__master_account {
//    top: 26%;
//    left: 40%;
//  }
//  &__reward {
//    top: 66%;
//    left: 40%;
//  }
//}
