@import "../../../shared/assets/styles/colors";
.title {
  margin: 2rem 0 0.3rem;
}
.head_container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  &__left {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    height: 70px;
    padding-top: 35px;

    span{
      margin: 0;
    }
  }
  &__right {
    display: flex;
    gap: 1.25rem;
  }
}
.table {
  vertical-align: 0;
  margin-top: 1rem;
  border-color: $gray-normal;
  // включает перенос в словах и таблица не выходит за рамки
  //word-break: break-word;
  // еще одно решение
  //table-layout: fixed;
  // решить проблему номрально при написании остальной части страницы!

  &__balance {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 15px top 20px;
    background-size: 16px 12px;
    position: relative;
    cursor: pointer;
    &__menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      left: 0;
      width: 100%;
      height: 48px;
      padding: 0 0.5rem;
      background-color: $gray-inactive;
      overflow-x: hidden;
      z-index: 1;
      cursor: pointer;
    }
  }
  .table_row {
    border-bottom: 1px solid $gray-normal;
    &:first-of-type {
      border-top: 2px solid $gray-normal;
    }
    &:last-child {
      border-bottom: 2px solid $gray-normal;
    }
    tr td {
      max-width: 10vw;
      border-bottom-width: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
    tr td:nth-child(odd) {
      padding-left: 30px;
      padding-right: 30px
    }
    tr td:first-child {
      padding-left: 10px;
      padding-right: 10px
    }
  }
  .strategy_name_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    height: 50px;
    border-bottom: 1px solid $gray-field;

    &:last-child{
      border-bottom:none;
    }

    .icon_wrapper{
      &:hover{
        cursor: pointer;
      }
    }
  }
  tr th {
    padding: 1rem 0.5rem;
  }
  tr th:nth-child(odd) {
    padding: 1rem 0;
  }
  tr th:last-child {
    padding: 1rem 0 1rem 0.5rem;
  }
  tr td:nth-child(even) {
    background-color: $gray-inactive;
  }
}
.green_font{
  color: $green-help !important;

}
.red_font{
  color: $red-error !important;

}
.black_font{
  color: $black;
}
.notification_text_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 269px;
}
.spinner {
  color: $blue-normal;
  position: absolute;
  right: 50%;
  top: 50%;
}
.subscriptions_td{
  padding: 0 !important;
}
.manage_icons_wrapper{
  display: flex;
  gap: 25px;

}
