@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.hint,
.error {
  height: 62px;
  width: 296px;
  background-color: $green-help;
  margin-left: 24rem;
  margin-top: 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: $white;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  position: absolute;


  @media screen and #{$media_mobile_max_width_3} {
    margin: 5px 0 0 0;
    position: relative;
    width: 100%;
  }

  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    position: absolute;
    pointer-events: none;
  }

  &:before {
    border-right-color: $green-help;
    border-width: 6px;
    margin-top: -16px;
    @media screen and #{$media_mobile_max_width_3} {
      border-width: 0;
    }
  }

}
.error {
  background-color: $red-error;

  &:before {
    border-right-color: $red-error;
  }
}
