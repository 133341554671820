@import "../../../shared/assets/styles/constants";
@import "../../../shared/assets/styles/colors";

.my_strategies_mobile{
  padding-bottom: 20px;
  height: 91vh;
  .action_buttons_wrapper{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin: 1.25rem 0;
    @media screen and #{$media_mobile_max_width_3} {
      margin-bottom: 25px;
    }

    button{
      width: 30%;
      height: 3.75rem;
    }
  }
}

.title {
  margin: 2rem 0 0.3rem;
  @media screen and #{$media_mobile_max_width_3} {
    margin: 1.25rem 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.notification_text_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 155px;
  span {
    &:last-child {
      text-align: center;
    }
  }
}
.head_container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  &__left {
    gap: 3rem;
  }
  &__right {

  }
}

.filter_block{
  display: flex;
  gap: 3%;

  .filter_icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    border: 1px solid $gray-field;
    background-color: $white;
    color: $black;
    box-shadow: none;
    border-radius: 10px;
    img{
      width: 8px;
    }
  }
}
.strategy_card_wrap {
  position: relative;

}
.strategy_card{
  width: 100%;
  min-height: 10rem;
  border-radius: 15px;
  background-color: $blue-normal;
  padding: 15px 20px;
  margin-top: 1.25rem;
  position: relative;

  &__active{
    background-color: $white;
    min-height: 396px;
    padding-bottom: 38px;
  }

  .show_icon{
    position: absolute;
    top: 25px;
    right: 34px;
  }

  .strategy_title_wrapper{
    border-bottom: 1px solid $dark-blue;
    padding-bottom: 13px;
    &__active{
      border-bottom: 1px solid $gray-field;
      padding-bottom: 0;
    }

    .strategy_title{
      display: flex;
      align-items: center;
      justify-content: space-between;


      .strategy_name{
        color: $white;
        &__active{
          color: $blue-normal;
        }
      }

    }
    .account_number_wrapper{
      display: none;

      &__active{
        display: block;
        margin-bottom: 20px;
        margin-top: 5px;
      }
    }
  }

  .info_block{
    display: flex;
    justify-content: flex-start;
    color: $white;
    width: 100%;
    margin-top: 10px;
    padding: 0 2.5rem;
    //padding-right: 40px;
    align-items: center;
    gap: 25%;
    @media screen and #{$media_mobile_max_width_2} {
      gap: 30%;
      padding: 0 1.25rem;
    }
    @media screen and #{$media_mobile_max_width_1} {
      gap: 20%;
    }
    &__active{
      justify-content: space-between;
      color: $black;
      gap: 10%;
      margin-top: 20px;
      padding: 0;
      @media screen and #{$media_mobile_max_width_2} {
        gap: 5%;

      }
    }
    .info_item{
      width: 50%;
      display: flex;
      flex-direction: column;
      //white-space: nowrap;
      span{
        &:first-child{
          margin-bottom: 5px;
        }
      }
    }
    .control_block_wrapper{
      display: none;

      &__active{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        //background-color: $white;
        color: $black;
        box-shadow: none;
        border-radius: 10px;

        &__null_balance{
          color: $red-error;
        }


        .control_icon{
          position: absolute;
          margin-right: 3rem;
          top: -20px;
        }

        .control_dropdown{
          top: 15px;
          right: 0;
          position: absolute;
          height: max-content;
          width: 3rem;
          border: 1px solid $gray-field;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: $white;
          .icon_wrapper{
            height: 46px;
            width: 100%;
            display: flex;
            &__delete_icon {
              width: 32px;
            }

            &:hover{
              background-color: $gray-normal;
            }
            img{
              width: 24px;
              margin: 0 auto;
            }
            &__delete_icon {
              img{
                width: 32px;
              }
            }
          }

          &:focus-visible {
            outline: none;
          }
        }

      }
    }
  }

  .content_wrapper{
    margin-top: 20px;

    .content_item{
      border-bottom: 1px solid $gray-normal;
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      span:last-child{
        text-align: right;

      }
    }
  }
}
.green_font{
  color: $green-help !important;

}
.red_font{
  color: $red-error !important;

}
.black_font{
  color: $black;
}
.spinner {
  color: $blue-normal;
  position: absolute;
  right: 50%;
  top: 50%;
  @media screen and #{$media_mobile_max_width_3} {
    right: 45%;
  }
}
