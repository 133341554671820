@import "../../../shared/assets/styles/colors";
@import "../../../shared/assets/styles/constants";

.button {
  background-color: $blue-normal;
  border-color: $blue-normal;
  box-shadow: none;
  border-radius: 10px;
  width: 11.5rem;
  height: 2.5rem;
  margin: 0;
  @media screen and #{$media_mobile_max_width_3} {
    width: 100%;
  }
  &__load_more {
    position: absolute;
    border: 1px solid #DDDDDD;
    background-color: #ffffff;
    box-shadow: none;
    border-radius: 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #797979;
    white-space: nowrap;
    @media screen and #{$media_mobile_max_width_3} {
      position: static;
      margin-top: 1.25rem;
      background-color: #DDDDDD;
      color: $blue-normal;
      width: 100%;
    }
    &:hover {
      border: 1px solid $gray-field;
      background-color: $white;
      color: $gray-text;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      @media screen and #{$media_desktop_max_width_3} {
        background-color: #DDDDDD;
        color: $blue-normal;
      }
    }
    &:focus {
      border: 1px solid $gray-field;
      background-color: $white;
      color: $gray-text;
      box-shadow: none;
      @media screen and #{$media_desktop_max_width_3} {
        background-color: #DDDDDD;
        color: $blue-normal;
      }
    }
    &:active:focus {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    }
  }
  &__subscribe,
  &__registration {
    background-color: white;
    border: 1px solid $gray-field;
    color: $blue-normal;
    box-shadow: none;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 24px;
    padding: 5px 8.5px;

    @media screen and (min-width: 1400px){
      width: 100%;
    }
    &:hover {
      background-color: $white;
      border: 1px solid $gray-normal;
      color: $blue-normal;
      box-shadow: none;
    }
    &:active {
      background-color: $white;
      border-color: $gray-normal;
      box-shadow: none;
    }
    &:focus {
      background-color: $white;
      border-color: $gray-normal;
      box-shadow: none;
      color: $blue-normal;
    }
    &:active:focus{
      box-shadow: none;
    }

    &__medium {
      height: 34px;
      padding: 5px 20px;
      border-radius: 10px;
      img{
        width: 21px;
        height: 21px;
      }
    }
    &__mobile {
      height: 2.5rem;
      width: 100%;
      padding: 8px 10px;
      border-radius: 10px;
      img{
        width: 21px;
        height: 21px;
      }
    }
  }
  &__search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    border: 1px solid $gray-field;
    background-color: $white;
    color: $black;
    box-shadow: none;
    border-radius: 10px;
    &:active {
      background-color: $white;
      border-color: $gray-normal;
      box-shadow: none;
    }
    &:focus {
      background-color: $white;
      border-color: $gray-field;
      box-shadow: none;
    }
    &:hover {
      background-color: $white;
      border-color: $gray-field;
      box-shadow: none;
    }
    &:active:focus{
      box-shadow: none;
    }
  }
  &__back {
    background-color: white;
    border: 1px solid $gray-field;
    color: $blue-normal;
    box-shadow: none;
    border-radius: 10px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;

    &:hover {
      background-color: $white;
      border: 1px solid $gray-normal;
      color: $blue-normal;
      box-shadow: none;
    }
    &:active {
      background-color: $white;
      border-color: $gray-normal;
      box-shadow: none;
    }
    &:focus {
      background-color: $white;
      border-color: $gray-normal;
      box-shadow: none;
      color: $blue-normal;
    }
    &:active:focus{
      box-shadow: none;
    }

    @media screen and #{$media_mobile_max_width_3} {
      width: 47%;
    }

    &__medium {
      height: 34px;
      padding: 5px 20px;
      img{
        width: 6px;
      }
    }

    @media screen and (min-width: 1400px){
      width: 100%;
    }

  }
  &__delete {
    background-color: white;
    border: 1px solid $gray-field;
    color: $red-error;
    box-shadow: none;
    border-radius: 10px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: $white;
      border: 1px solid $gray-normal;
      color: $red-error;
      box-shadow: none;
    }
    &:active {
      background-color: $white;
      border-color: $gray-normal;
      box-shadow: none;
    }
    &:focus {
      background-color: $white;
      border-color: $gray-normal;
      box-shadow: none;
      color: $red-error;
    }
    &:active:focus{
      box-shadow: none;
    }

    @media screen and #{$media_mobile_max_width_3} {
      width: 47%;
    }

    &__medium {
      height: 34px;
      padding: 5px 20px;
      img{
        height: 18px;
      }
    }

    @media screen and (min-width: 1400px){
      width: 100%;
    }

  }
  &__disable {
    background-color: $gray-normal;
    border-color: $gray-normal;
    box-shadow: none;
    border-radius: 10px;
  }
  &:focus {
    background-color: $blue-normal;
    border-color: $blue-normal;
    box-shadow: none;
  }
  &:active{
    background-color: $blue-active;
    border-color: $blue-active;
    box-shadow: none;
  }
  &:active:focus{
    box-shadow: none;
  }
  &:hover {
    background-color: $blue-hover;
    border-color: $blue-hover;
    box-shadow: none;
  }
  &__cancel {
    border: 1px solid $gray-field;
    background-color: $white;
    color: $blue-normal;
    box-shadow: none;
    border-radius: 10px;
    width: 11.5rem;
    height: 2.5rem;
    margin: 0;

    @media screen and #{$media_mobile_max_width_3} {
      width: 100%;
    }

    &:hover {
      background-color: $white;
      border: 1px solid $gray-normal;
      color: $blue-normal;
      box-shadow: none;
    }
    &:active:focus{
      box-shadow: none;
    }
  }
  &__manage {
    border: 1px solid $gray-field;
    background-color: $white;
    color: $black;
    box-shadow: none;
    border-radius: 10px;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 44px;
    img {
      @media screen and #{$media_mobile_max_width_3}{
        width: 34px;
      }
    }
    &:hover {
      border: 1px solid $gray-normal;
      background-color: $white;
      color: $black;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    &:focus {
      border: 1px solid $gray-field;
      background-color: $white;
      color: $black;
      box-shadow: none;
      border-radius: 10px;
    }
    &:active:focus{
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    }
  }
  &__table_header {
    border: 1px solid $gray-field;
    background-color: $white;
    box-shadow: none;
    border-radius: 10px;
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-text;
    // чтобы не переносилсь слова и не вылазили за рамки кнопок в th
    // в идеале, сделать отзывчивость, чтобы масштабировалось
    white-space: nowrap;
    padding: 7px 4px 7px 10px;

    &:hover {
      border: 1px solid $gray-field;
      background-color: $white;
      color: $gray-text;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
    &:focus {
      border: 1px solid $gray-field;
      background-color: $white;
      color: $gray-text;
      box-shadow: none;
    }
    &:active {
      border: 1px solid $gray-field;
      background-color: $white;
      box-shadow: none;
    }
    &:active:focus{
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    }
    &:disabled {
      background-color: $gray-inactive;
      border-color: $gray-inactive;
      box-shadow: none;
      color: $gray-text;
    }
    &__active_header_btn_border {
      border-color: $blue-normal;
      color: $blue-normal;
      &:active,
      &:hover,
      &:focus {
        border-color: $blue-normal;
        color: $blue-normal;
      }
    }
  }

  &__info{
    width: 24px;
    height: 24px;
    border: none;
    background: url("../../../shared/assets/icons/info_icon.svg") no-repeat;
    &:hover {
      background: url("../../../shared/assets/icons/info_hover_icon.svg") no-repeat;
    }
    &:active{
      box-shadow: none;
    }

    &:focus {
      // !important - перезаписывает стили реактстрапа
      box-shadow: none !important;
      background-color: transparent!important;
      border-color: transparent!important;
    }

  }
}
