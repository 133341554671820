@import "../../../shared/assets/styles/constants";
@import "../../../shared/assets/styles/colors";

.my_strategies_mobile {
  padding-bottom: 20px;
  height: 91vh;
  .action_buttons_wrapper {
    display: flex;
    gap: 11px;
    justify-content: space-between;
    margin: 1.25rem 0;

    button {
      width: 30%;
      height: 3.75rem;
    }
  }
}
.input_container {
  display: flex;
  position: relative;
  align-items: center;
  @media screen and #{$media_mobile_max_width_3} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start
  }
  input {
    border: 1px solid $gray-field;
    border-radius: 10px;
    color: $blue-normal;
    font-family: Gotham-Book, sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
    &:focus {
      border-color: $gray-field;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      background-color: $white;
      color: $blue-normal;
    }
    &::placeholder {
      font-family: Gotham-Book, sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.title {
  margin: 2rem 0 0.3rem;
  @media screen and #{$media_mobile_max_width_3} {
    margin: 1.25rem 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.notification_text_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 155px;
}
.filter_block {
  display: flex;
  gap: 3%;

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    border: 1px solid $gray-field;
    background-color: $white;
    color: $black;
    box-shadow: none;
    border-radius: 10px;
    img {
      width: 11px;
    }
  }
}
.strategy_card {
  width: 100%;
  height: 120px;
  border-radius: 15px;
  background-color: $blue-normal;
  color: $white;
  padding: 23px 20px 19px 20px; //поменять этот бред
  margin-top: 1.25rem;
  position: relative;

  &__active {
    background-color: $white;
    min-height: 28rem;
  }
  .strategy_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $dark-blue;
    padding-bottom: 13px; // сделать кратные 5 числа
  }
  .strategy_title_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70vw;
  }
  .show_icon {
    position: absolute;
    right: 33px;
    top: 18px;
  }
  }

  .info_block {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
    padding: 0 2.5rem;
    align-items: center;
    gap: 25%;
    @media screen and #{$media_mobile_max_width_2} {
      gap: 30%;
      padding: 0 1.25rem;
    }
    @media screen and #{$media_mobile_max_width_1} {
      gap: 20%;
    }
    &__active {
      justify-content: space-between;
      color: $black;
      gap: 10%;
      margin-top: 20px;
      padding: 0;
      @media screen and #{$media_mobile_max_width_2} {
        gap: 5%;

      }
    }
    .info_item {
      width: 50%;
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      span{
        &:first-child{
          margin-bottom: 5px;
        }
      }
    }
    .show_more {
      position: initial;
    }
    .control_block_wrapper{
      display: none;

      &__active{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        //background-color: $white;
        color: $black;
        box-shadow: none;
        border-radius: 10px;

        &__null_balance{
          color: $red-error;
        }


        .control_icon{
          position: absolute;
          margin-right: 3rem;
          top: -20px;
        }

        .control_dropdown{
          top: 15px;
          right: 0;
          position: absolute;
          height: max-content;
          width: 3rem;
          border: 1px solid $gray-field;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: $white;
          .icon_wrapper{
            height: 46px;
            width: 100%;
            display: flex;

            &:hover{
              background-color: $gray-normal;
            }
            img{
              width: 24px;
              margin: 0 auto;
            }
          }

          &:focus-visible {
            outline: none;
          }
        }

      }
    }
  }

  .content_wrapper{
    margin-top: 20px;

    .content_item{
      border-bottom: 1px solid $gray-normal;
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }

      span:last-child{
        text-align: right;

      }

    }
  }
.green_font{
  color: $green-help !important;

}
.red_font{
  color: $red-error !important;

}
.black_font{
  color: $black;
}
.spinner {
  color: $blue-normal;
  position: absolute;
  right: 45%;
  top: 50%;
}
.pagination_bar {
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin-top: 1rem;
}
