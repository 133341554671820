@import "../../../shared/assets/styles/colors";
@import "../../../shared/assets/styles/constants";

.btn_group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  background-color: $gray-inactive;
  border: 1px solid $gray-inactive;
  border-radius: 15px;
  padding: 5px;
  height: 44px;
  gap: 10px;
  @media screen and #{$media_mobile_max_width_3}{
    background-color: $gray-lite;
    border: 1px solid $gray-lite;
    width: 100%;
    height: 46px;

  }
}
.btn {
  color: $gray-element;
  background-color: $gray-inactive;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  height: 34px;
  @media screen and #{$media_mobile_max_width_3}{
    background-color: $gray-lite;
    width: 100%;
    height: 36px;

  }
  &.active {
    color: $blue-normal !important;
    background-color: $white;
    box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  }
  &:hover {
    color: $gray-element;
    background-color: $white;
    box-shadow: 0 1px 4px rgb(0 0 0 / 20%)
  }
  &:focus {
    color: $blue-normal;
    background-color: $white;
    box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
    border: none;
  }
  &:active:focus{
    // отключает анимацию тени при клике
    box-shadow: none;
  }
}
.toggle_wrapper{
  @media screen and #{$media_mobile_max_width_3}{
    margin-bottom: 25px;
  }
}
