@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.modal_content {
  border-radius: 20px;
  padding: 40px;
  width: 29.5rem;
  min-height: 16.9rem;

  @media screen and #{$media_mobile_max_width_3} {
    height: 100%;
    width: 100vw;
    border-radius: 0;
    border: none;
    padding: 53px 20px 20px 20px;
  }

  &__request_id {
    padding: 40px 40px 56px;
    @media screen and #{$media_mobile_max_width_3} {
      padding: 53px 20px 20px 20px;
    }
  }
}
.modal_body{
  margin-top: 2.2rem;
  padding: 0;
  @media screen and #{$media_mobile_max_width_3} {
    margin-top: 30px;
    flex-grow: 0;
    padding: 0;
  }
}
.body_wrapper {
  display: flex;
  gap: 0.3rem;
  padding: 0;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  outline: 0;
  @media screen and #{$media_mobile_max_width_3} {
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    outline: 0;
  }
}
.modal_window {
  width: 29.5rem;
  @media screen and #{$media_mobile_max_width_3} {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    //top: 5rem;
  }
}
.btn_close {
  box-sizing: content-box;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
  position: absolute;
  height: 6px;
  width: 6px;
  right: -10px;
  top: -10px;
  @media screen and #{$media_mobile_max_width_3} {
    height: 10px;
    width: 10px;
    right: 0px;
    top: -33px;
  }
  &:focus{
    box-shadow: none;
  }

}
.btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2.2rem 0 0 0;
  //&__space_between{
  //  justify-content: space-between;
  //}
  //justify-content: center;
  @media screen and #{$media_mobile_max_width_3} {
    flex-direction: column;
    margin: 0;
    gap: 20px;
  }
  &__space_between{
    justify-content: space-between;
  }

}
.header {
  border-bottom: none;
  position: relative;
  padding: 0;
  @media screen and #{$media_mobile_max_width_3}{
    padding-bottom: 0;
    padding-left: 0;
  }
}
.footer {
  border-top: none;
  justify-content: center;
  gap: 1rem;
  padding: 0;
  position: relative;
  .body_wrapper {
    position: absolute;
    bottom: -36px;
  }
  @media screen and #{$media_mobile_max_width_3}{
    padding: 0;
    margin-top: 30px;
  }
}
.title_wrapper{
  display: flex;
  justify-content: flex-start;

  img{
    margin-right: 1rem;
  }
}
@keyframes animateborder {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.requestID {
  cursor: pointer;
  &__copied {
    animation: animateborder .3s ease-in-out;
  }
}

