@import "../../../shared/assets/styles/constants";
@import "../../../shared/assets/styles/colors";

.modal_content {
  border-radius: 20px;
  padding: 40px;
  min-height: 650px;
  //height: 76vh; адаптивный размер относительно экрана макетов
  @media screen and #{$media_mobile_max_width_3} {
    height: 100%;
    border-radius: 0;
    padding: 40px 20px;
    border: none;
  }
}
.modal_window {
  max-width: 670px;
  @media screen and #{$media_mobile_max_width_3} {
    max-width: 100%;
    height: 100%;
    margin: 0;
    //top: 5rem;
  }
}
.modal_body {
  padding: 0;
  @media screen and #{$media_mobile_max_width_3} {
    flex-grow: 0;
  }
}
.header {
  border-bottom: none;
  position: relative;
  padding: 0 0 30px 0;

  span{
    margin-top: 1rem;
  }

  .info_wrapper {
    gap: 19px;
    display: flex;
    align-items: center;
    position: absolute;
    top: -1.2rem;
    right: 2rem;

    @media screen and #{$media_mobile_max_width_3} {
      top: -1.8rem;
      right: 2rem;
    }


    .info_border{
      border: 1px solid $gray-light;
      width: 1px;
      height: 30px;
    }
  }

  .title_wrapper{
    margin-top: 1rem;
  }

  h5{
    width: 100%;
  }
}
.footer {
  border-top: none;
  justify-content: flex-start;
  gap: 20px;
  padding: 0;
  @media screen and #{$media_mobile_max_width_3} {
    margin-top: 16px;
    flex-direction: column;
    gap: 20px;
  }
  button {
    @media screen and #{$media_mobile_max_width_3} {
      margin: 0;
    }
  }
}
.main_wrapper__mobile{
  width: 47%;
}
.btn_close {
  box-sizing: content-box;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
  margin: -0.5rem -0.5rem -0.5rem auto;
  position: absolute;
  height: 8px;
  width: 8px;
  right: 0.2vw;
  top: -0.3vh;
  @media screen and #{$media_mobile_max_width_3} {
    height: 7px;
    width: 7px;
    right: 5px;
    top: -15px;
  }
}
