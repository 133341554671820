@import "../../shared/assets/styles/colors";

.toggle_switch {
  position: relative;
  min-width: 55px;
  display: inline-block;
  text-align: left;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid $gray-normal;
  border-radius: 10px;
  height: 30px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.1s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  color: $white;
  //font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 13'%3e%3cpath fill='white' d='M4.77273 9.8958L1.19318 6.25593L0 7.46922L4.77273 12.3224L15 1.92276L13.8068 0.709473L4.77273 9.8958Z'/%3e%3c/svg%3e");
  padding-left: 8px;
  padding-right: 32px;
  background-color: $blue-normal;
  color: $white;
}
.inner:after {
  content: " ";
  background-color: $gray-normal;
  color: $white;
}
.switch {
  display: block;
  width: 24px;
  height: 24px;
  margin: 3px;
  background: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0 solid $gray-normal;
  border-radius: 8px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0;
}
.toggle_text_container {
  min-width: 98px;
  display: flex;
  align-items: center;
  gap: 10px;
}
