@import "../../shared/assets/styles/constants";
@import "../../shared/assets/styles/colors";

.logo_wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  @media screen and #{$media_mobile_max_width_3} {
    height: 80vh;
  }
}
.spinner {
  color: $blue-normal;
  position: absolute;
  right: 50%;
  top: 50%;
  @media screen and #{$media_mobile_max_width_3} {
    right: 45%;
  }
}
