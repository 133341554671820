@import "../../shared/assets/styles/_colors";
@import "../../shared/assets/styles/constants";

.main_layout {
	box-sizing: border-box;
	padding: 0 2.5rem;

	@media screen and #{$media_mobile_max_width_3}{
		padding: 0 10px;
		padding-block-start: 58px;
	}

	&__block_shadow {
		display: none;
		box-shadow: 0.2rem 0 0 0 rgba(0, 0, 0, 0.15);

		@media screen and #{$media_mobile_max_width_3} {
			position: fixed;
			//background-color: #8b8b8b71;
			background-color: $white;
			top: 6.1rem;
			height: calc(100vh - 6.1rem);
			width: 100vw;
			z-index: 2;
			outline: none;
		}

		&__open {
			@media screen and #{$media_desktop_max_width_6} {
				display: flex;
				position: fixed;
				//background-color: #8b8b8b71;
				background-color: $white;
				opacity: .71;
				top: 8rem;
				height: calc(100vh - 8rem);
				width: 100vw;
				z-index: 2;
				outline: none;
			}

			@media screen and #{$media_mobile_max_width_3} {
				display: block;
				top: 6.1rem;
				height: calc(100vh - 6.1rem);
			}
		}
	}
}

