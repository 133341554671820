@import "../../../shared/assets/styles/colors";
@import "../../../shared/assets/styles/constants";

.spinner {
  color: $blue-normal;
  position: absolute;
  right: 50%;
  top: 50%;
}
.content_wrapper {
  display: flex;
  flex-direction: column;
}
.page_title_wrapper {
  margin: 2rem 0 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.7vw;

  .buttons_wrapper {
    display: flex;
    gap: 1.7vw;
  }
  &__left_side {
    display: flex;
    align-items: center;
    gap: 35px;
  }
}
.wrap_container {
  display: flex;
  justify-content: space-between;
  gap: 10%;
}
.info_block_wrapper {
  margin-top: 85px;
  width: 35vw;
}
.info_row{
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 35vw;
  border-bottom: 1px solid $gray-normal;

  &:last-child{
    border-bottom: none;
  }

  .info_row_item{
    //width: 18vw;
    display: flex;
    align-items: center;
    &:first-child{
      padding-left: 5px;
    }
  }
}
.subscription_terms_wrapper{
  margin-top: 58px;

  .info_row{
    margin-top: 30px;
  }
}
.chart_container {
  position: relative;
  height: 500px;
}
.green_font{
  color: $green-help !important;

}
.red_font{
  color: $red-error !important;

}
.black_font{
  color: $black;
}
