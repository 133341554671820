@import "../../shared/assets/styles/colors";
.btn_group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  background-color: $gray-inactive;
  border-radius: 15px;
  padding: 5px;
  gap: 10px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-element;
  background-color: $gray-inactive;
  border-radius: 10px;
  border: none;
  min-width: 137px;
  line-height: initial;
  padding: 13px;
  &.active {
    color: $black !important;
    background-color: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  &:hover {
    color: $gray-element;
    background-color: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    color: $black;
    background-color: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  &:active:focus {
    //тень при клике
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
}
