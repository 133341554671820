@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";
.support_link_wrapper{
	position: relative;
	display: flex;
	align-items: center;
	gap: 10px;
	border: 1px solid #DDDDDD;
	border-radius: 10px;
	padding: 0.6rem 0.9rem;
	background-color: #ffffff;
	color: $gray-element;
	text-decoration: none;

	@media screen and #{$media_mobile_max_width_3} {
		border: none;
		padding: 0 0 0 20px;
	}

	&:hover{
		color: $gray-element;
		box-shadow: 0 1px 4px rgb(0 0 0 / 0.1);
		border: 1px solid $gray-normal;

		@media screen and #{$media_mobile_max_width_3} {
			box-shadow: none;
			border: none;
		}
	}

	&:active{
		border-color: $blue-normal;
		color: $blue-normal;

		@media screen and #{$media_mobile_max_width_3} {
			box-shadow: none;
		}
	};


	.support_link{
		@media screen and #{$media_mobile_max_width_3} {
			display: none;
		}
	}
}





