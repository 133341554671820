@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.pagination_container {
  display: flex;
  list-style-type: none;
  //&__unfilled {
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //}

  .pagination_item {
    height: 40px;
    text-align: center;
    margin: auto 4px;
    color: $gray-element;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    border-radius: 20px;
    min-width: 40px;
    &:hover {
      background-color: $gray-inactive;
      cursor: pointer;
    }

    &__selected {
      background-color: $gray-inactive;
      color: $blue-normal;
      @media screen and #{$media_mobile_max_width_3} {
        background-color: $white;
      }
    }

    //&.disabled {
    //  pointer-events: none;
    //  &:hover {
    //    background-color: transparent;
    //    cursor: default;
    //  }
    //}
  }
}
