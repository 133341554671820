@import "../../shared/assets/styles/_colors";
@import "../../shared/assets/styles/constants";


.container_fluid{
  padding: 0;
  flex-wrap: nowrap;

  .logo_nav_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 143px;
  }

  .logo_wrapper{

    @media
    #{$media_mobile_max_width_3},
    #{$media_mobile_max_width_and_max_height_1}
    {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .burger_menu {
      display: none;
      @media
      #{$media_mobile_max_width_3},
      #{$media_mobile_max_width_and_max_height_1}
      {
        display: block;
        margin-right: 1rem;
      }
    }

    .logo {
      @media screen and #{$media_mobile_max_width_1} {
        width: 7rem;
      }

    }

  }
}
.nav_bar {
  justify-content: center;
  @media screen and #{$media_desktop_max_width_4}{
    display: none;
  }
}

.main_nav {
  padding: 0 2.5rem 0 2.5rem;
  border-bottom: 1px solid $gray-field;
  background-color: white!important;
  height: 5rem;

  @media screen and #{$media_mobile_max_width_3} {
    padding: 0 1.25rem;
    height: 58px;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: 1040; // из-за модального окна, у которого 1050 (ад)
  }

}
.ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 62px;

  .nav_item{
    list-style: none;

    .nav_link{
      padding-left: 0!important;
      padding-right: 0!important;
    }

  }
}
.active {
  border-bottom: 3px solid $blue-normal;
  padding-bottom: 5px;
  color: $black !important;
}
.nav_link_props {
  transition: color .2s ease-in-out,background-color .2s ease-in-out;
  display: flex;
  align-items: center;
  height: 5.2rem;
  //padding: 28px 0 !important;
  color: $gray-element;

  &:hover{
    color: $gray-element;
  }
}

.control_wrapper{
  display: flex;
  flex-direction: row;
  gap: .625rem;

  @media screen and #{$media_mobile_max_width_1} {
    gap: 0;
  }
}

