@import "../../shared/assets/styles/_colors";
@import "../../shared/assets/styles/constants";

.mobile_menu_wrapper{

	width: 100vh;
	background-color: $blue-normal;
	position: absolute;
	top: 0;
	//z-index: 5;
	z-index: 1100; // из-за модального окна, у которого 1050 (ад)
	transform: translate3d(0, -55rem, 0);
	transition: all .2s ease;
	&__open{
		transform: translate3d(0, 0, 0);
	}
	.close_icon_wrapper{
		display: flex;
		padding-left: 1.5rem;
		height: 60px;
		border-bottom: 1px solid $gray-field;

		.close_icon{
			width: 0.875rem;
			cursor: pointer;
		}
	}
	.nav_link{
		transition: color .2s ease-in-out, background-color .2s ease-in-out;
		padding: 0.8rem 0 0.8rem 1.5rem;
		color: $gray-field;
		&:hover {
			color: $white;
		}
	}

}
.active {
	span{
		border-bottom: 3px solid $white;
		height: 5rem;
		color: $white;
	}

}
