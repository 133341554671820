@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.dropdown {
  display: flex;
  margin-top: 1rem;
  align-items: center;

 &__btn {
   display: flex;
   height: 40px;
   width: 100%;
   padding: 0.375rem 3.1rem 0.375rem 0.75rem;
   align-items: center;
   cursor: pointer;
   justify-content: space-between;
   font-family: Gotham, sans-serif;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: $black;
   border-radius: 10px;
   border: 1px solid $gray-field;
   background-color: $white;
   transition: border-color .2s ease-in-out,box-shadow .2s ease-in-out;
   appearance: none;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   position: relative;
   &__arrow {
     display: flex;
     position: absolute;
     right: 0.75rem;
     &__hideArrow {
       display: none;
     }
   }
   &__noArrowImg {
     //background-image: none;
     padding: 0.375rem 1rem 0.375rem 0.75rem;
     &__noPointer {
       cursor: auto;
     }
   }
   &:hover,
   &__focus {
     border: 1px solid $gray-normal;
     box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
   }
 }
  &__content {
    position: absolute;
    width: 100%;
    max-height: 280px;
    background-color: $white;
    border: 1px solid $gray-field;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
    overflow-x: hidden;
    z-index: 1;
    &::-webkit-scrollbar{
      width: 8px;
    }
    &::-webkit-scrollbar-thumb{
      background: $gray-scroll;
      border-radius: 10px;
      scroll-margin-left: 20px;
    }
    @media screen and #{$media_mobile_max_width_3} {
      z-index: 1;
      width: 100%;
    }
    &__item {
      padding: 10px;
      cursor: pointer;
      transition: all 0.2s;
      border-bottom: 1px solid $gray-field;
      //height: 38px;
      display: flex;
      justify-content: space-between;
      box-sizing: content-box;
      &:last-child {
        border-bottom: 0
      }
      &:hover {
        //border: 2px solid #2D64E9;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        background-color: $gray-inactive;
        //border-radius: 10px;
      }
      &:focus-visible {
        outline: none;
      }
      &__isActive {
        background-color: $background-blue !important;
        color: $blue-normal;
      }
    }
    &__filter {
      @media screen and #{$media_mobile_max_width_3} {
        width: 100%;
      }
    }
  }
}
.arrow {
  height: 0.8rem;
}
.input {
  font-family: Gotham-Book, sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: initial;
  border: none;
  padding: 0;
  color: $blue-normal;
  &:hover,
  &:active,
  &:focus,
  &:read-only,
  &:focus-visible {
    border: none;
    box-shadow: none;
    cursor: pointer;
    background-color: $white;
    text-overflow: ellipsis;
  }
  &__noPointer {
    &:hover,
    &:active,
    &:focus,
    &:read-only,
    &:focus-visible {
      cursor: auto;
      //background-color: $gray-inactive;
    }
  }
}
