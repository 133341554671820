@import "../../shared/assets/styles/colors";
.text {
  &--type--h1 {
    font-family: Gotham-Bold, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
  }
  &--type--h2 {
    font-family: Gotham-Bold, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
  &--type--h3 {
    font-family: Gotham-Bold, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }
  &--type--mobile-menu {
    font-family: Gotham-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
  &--type--button {
    font-family: Gotham-Medium, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
  &--type--text {
    font-family: Gotham-Book, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
  &--type--account {
    font-family: Gotham-Book, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
  &--type--notification-text {
    font-family: Gotham-Book, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
  &--type--page-notification {
    font-family: Gotham-Medium, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &--type--page-notification-mobile {
    font-family: Gotham-Book, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  &--type--sub-description {
    font-family: Gotham-Book, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }
  &--type--info {
    font-family: Gotham-Book, sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  // aligns

  &--align--left {
    text-align: left;
  }

  &--align--center {
    text-align: center;
  }

  &--align--right {
    text-align: right;
  }

  // colors

  &--color--white {
    color: $white;
  }

  &--color--gray {
    color: $gray-element;
  }

  &--color--black {
    color: $black;
  }
  &--color--blue {
    color: $blue-normal;
  }

}
