@import "../../../shared/assets/styles/colors";
@import "../../../shared/assets/styles/constants";

.spinner {
  color: $blue-normal;
  position: absolute;
  right: 45%;
  top: 50%;
}
.chart_container {
  height: 450px;
  width: 99vw;
}
.container {
  margin: 100px;
}
.title_wrapper{
  // из-за нижнего бордера и тени в дизайне
  position: absolute;
  width: 100%;
  left: 0;
  background-color: $white;
}
.title{
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-field;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  .title_item{
    // из-за нижнего бордера и тени в дизайне
    padding: 0 20px;
    display: flex;
    flex-direction: column;
  }
  .buttons_wrapper{
    // из-за нижнего бордера и тени в дизайне
    padding: 0 20px;
    // ----
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
}
.info_block_wrapper{
  margin-top: 37px;
  width: 100%;
  padding: 0 20px;
}
.info_row{
  display: flex;
  height: 50px;
  //padding-right: 19px; в дизайне так, но решение не верное
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $gray-normal;

  &:last-child{
    border-bottom: none;
  }

  .info_row_item{
    width: 100%;
    display: flex;
    align-items: center;
    &:last-child{
      justify-content: flex-end;
      text-align: end;

    }
  }
}
.subscription_terms_wrapper{
  margin-top: 20px;
  padding: 0 20px;
  .subscription_terms_label{
    .subscription_terms{

    }
  }
  .info_row{
    margin-top: 20px;
  }
}
