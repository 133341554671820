@import "../../../shared/assets/styles/colors";
@import "../../../shared/assets/styles/constants";

.content_wrapper {
  display: flex;
  flex-direction: column;
}
//.chart_container {
//  height: 500px;
//}
.wrap_container {
  display: flex;
  justify-content: space-between;
  gap: 10%;
}
.spinner {
  color: $blue-normal;
  position: absolute;
  right: 50%;
  top: 50%;
}
.page_title_wrapper {
  margin: 2rem 0 0.3rem;
  display: flex;
  justify-content: space-between;
  gap: 2.7vw;

  .buttons_wrapper {
    display: flex;
    gap: 1.7vw;
  }
}
.info_block_wrapper {
  margin-top: 85px;
  width: 35vw;
}
.info_row {
  display: flex;
  height: 50px;
  border-bottom: 1px solid $gray-normal;

  &:last-child {
    border-bottom: none;
  }

  .info_row_item {
    width: 18vw;
    display: flex;
    align-items: center;
    &:first-child {
      padding-left: 5px;
    }
    &:last-child {
      justify-content: end;
    }
  }
}
.subscription_terms_wrapper {
  margin-top: 58px;

  .info_row {
    margin-top: 30px;
  }
}
