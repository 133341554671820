@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.chart_wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 70px;
  width: 100%;
  height: 500px;
  @media screen and #{$media_mobile_max_width_3} {
    width: 100vw;
    margin-top: 10px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb{
    background-color: #c1c1c1;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background-color: #b6b6b6;
  }
}
.spinner {
  color: $blue-normal;
  position: absolute;
  right: 50%;
  top: 50%;
  @media screen and #{$media_mobile_max_width_3} {
    right: 45%;
  }
}
