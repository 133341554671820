@import "../../../shared/assets/styles/constants";
@import "../../../shared/assets/styles/colors";

.modal_content {
  border-radius: 20px;
  padding: 40px;
  min-height: 730px;
  height: 81vh;
  border: none;
  @media screen and #{$media_mobile_max_width_3} {
    height: 100%;
    border-radius: 0;
    padding: 40px 20px;
    border: none;
  }
}
.modal_window {
  width: 50vw;
  max-width: 50vw;
  @media screen and #{$media_mobile_max_width_3} {
    max-width: 100%;
    height: 100%;
    margin: 0;
    width: 100%;
  }
}
.modal_body {
  padding: 0;
  @media screen and #{$media_mobile_max_width_3} {
    flex-grow: 0;
  }
}
.header {
  border-bottom: none;
  position: relative;
  padding: 0;
  //padding: 0 0 40px 0;
  margin-bottom: 40px;
}

.main_wrapper__mobile{
  width: 47%;
}
.btn_close {
  box-sizing: content-box;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
  margin: -0.5rem -0.5rem -0.5rem auto;
  position: absolute;
  height: 6px;
  width: 6px;
  right: -10px;
  top: -10px;
  @media screen and #{$media_mobile_max_width_3} {
    height: 7px;
    width: 7px;
    right: 5px;
    top: -15px;
  }
}
.info_content_wrapper{
  height: 67vh;
  min-height: 67vh;
  overflow-y: scroll;
  // работает только для firefox
  // только 2 значения auto и thin
  scrollbar-width: auto;
  scrollbar-color: $gray-scroll transparent;
   &::-webkit-scrollbar{
     width: 20px;
     border-radius: 5px;
     scroll-margin-left: 20px;
   }
  &::-webkit-scrollbar-thumb{
    background: $gray-scroll;
    height: 70px;
  }

  .content{
    font-family: Gotham-Book, sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
    @media screen and #{$media_mobile_max_width_3} {
      font-size: 12px;
      line-height: 16px;
    }

  }
}
