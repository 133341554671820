$green-help: #01A66B;
$red-error: #CF4487;
$background-blue: #CAD8F9;
$blue-hover: #5284ff;
$blue-normal: #2d64e9;
$blue-active: #234EB8;
$dark-blue: #2B56BC;
$white: #ffffff;
$gray-inactive: #F5F5F5;
$gray-lite: #E6E6E6;
$gray-light: #E5E5E5;
$gray-field: #DDDDDD;
$gray-scroll: #D9D9D9;
$gray-normal: #BBBBBB;
$gray-text: #797979;
$gray-element: #717171;
$black: #000000;
