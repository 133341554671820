@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.wrapper {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px 6px;
  @media screen and #{$media_mobile_max_width_3} {
    display: flex;
    height: auto;
  }
}
