@import "../../shared/assets/styles/colors";
@import "../../shared/assets/styles/constants.scss";

.logout_wrapper {
	position: relative;
	display: flex;
	align-items: center;
	height: 2.75rem;
	border: 1px solid $gray-field;
	border-radius: 10px;
	padding: 0.6rem 0.9rem;
	background-color: $white;
	cursor: pointer;

	@media screen and #{$media_mobile_max_width_3} {
		border: none;
		padding: 0 0 0 20px;

	}

	&:hover{
		box-shadow: 0 1px 4px rgb(0 0 0 / 0.1);
		border: 1px solid $gray-normal;

		@media screen and #{$media_mobile_max_width_3} {
			box-shadow: none;
			border: none;
		}
	};



	//&:active{
	//	border-color: $blue-normal;
	//};
}
